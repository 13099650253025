import { msmPageTemplatesMap } from '@clatter/platform';
import routes from '../routes/routes';

const mockBlocks = {
  'Partner Site': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Bios',
      path: routes.pageBios,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Partner Site - Coley': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Bios',
      path: routes.pageBios,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Partner Site - Optum Rx brand': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Bios',
      path: routes.pageBios,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Partner Site - Optum brand': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Bios',
      path: routes.pageBios,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  // Prospect Landing Page is a synonym for Prospect Site
  'Prospect Landing Page': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
  ],
  'Prospect Site': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Prospect Site UHC': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Prospect Site UHC - Optum': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.UHC_MAIN]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.LANDING_PAGE_MA]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.LANDING_PAGE_UHC]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.URS_MAIN_MA]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.PROSPECT_SITE_UHC_SUREST]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Prospect Site UHC - UMR': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Prospect Site - Coley': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  'Additional Resources': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Resources - Coley': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Resources - Optum Rx brand': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.UHC_ADDITIONAL_RESOURCES]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.URS_ADDITIONAL_RESOURCES_MA]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Resources - Optum brand': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.ADDITIONAL_RESOURCES_OPTUM_FINANCIAL]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Resources with videos': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Resources with videos - Optum Rx brand': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Resources with videos - Optum brand': [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Videos',
      path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.ADDITIONAL_RESOURCES_WITH_VIDEOS_OPTUM_FINANCIAL]: [
    {
      name: 'Banner',
        path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Videos',
        path: routes.pageVideos,
      isComplete: false,
    },
    {
      name: 'Resources',
        path: routes.pageResources,
      isComplete: false,
    },
  ],
  'Additional Videos': [
    {
      name: 'Videos Table',
      path: routes.pageVideosTable,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.UHC_VIDEO]: [
    {
      name: 'Videos Table',
      path: routes.pageVideosTable,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.URS_VIDEO_MA]: [
    {
      name: 'Videos Table',
      path: routes.pageVideosTable,
      isComplete: false,
    },
  ],
  'Video Template': [
    {
      name: 'Videos Table',
      path: routes.pageVideosTable,
      isComplete: false,
    },
  ],
  'Video Template - Coley': [
    {
      name: 'Videos Table',
      path: routes.pageVideosTable,
      isComplete: false,
    },
  ],
  'Landing Page': [
    {
      name: 'Internal Resources',
      path: routes.pageResourcesTable,
      isComplete: false,
    },
    {
      name: 'Quick Links',
      path: routes.pageQuickLinks,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.DOWNLOAD_DECKS_UHC]: [
    {
      name: 'Quick Links',
      path: routes.pageQuickLinks,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResourcesTable,
      isComplete: false,
    },
  ],
  'Landing Page - Coley': [
    {
      name: 'Internal Resources',
      path: routes.pageResourcesTable,
      isComplete: false,
    },
    {
      name: 'Quick Links',
      path: routes.pageQuickLinks,
      isComplete: false,
    },
  ],
  Payer: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Content',
      path: routes.pageContent,
      isComplete: false,
    },
    {
      name: 'Additional Information',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_RX]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Content',
      path: routes.pageContent,
      isComplete: false,
    },
    {
      name: 'Additional Information',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_FINANCIAL]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Content',
      path: routes.pageContent,
      isComplete: false,
    },
    {
      name: 'Additional Information',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.LIFE_SCIENCES_OPTUM_BRAND]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Quick Links',
      path: routes.pageQuickLinks,
      isComplete: false,
    },
    {
      name: 'Optional Copy Blocks',
      path: routes.pageOptionalCopyBlock,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.PROSPECT_SITE_WITH_RESOURCES_AND_LIBRARY_OPTUM_RX]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Quick Links',
      path: routes.pageQuickLinks,
      isComplete: false,
    },
    {
      name: 'Optional Copy Blocks',
      path: routes.pageOptionalCopyBlock,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.RESOURCE_AND_DOCUMENT_LIBRARY_OPTUM_BRAND]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Resources',
      path: routes.pageResources,
      isComplete: false,
    },
    {
      name: 'Quick Links',
      path: routes.pageQuickLinks,
      isComplete: false,
    },
    {
      name: 'Optional Copy Blocks',
      path: routes.pageOptionalCopyBlock,
      isComplete: false,
    },
    {
      name: 'Footer',
      path: routes.pageFooter,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.RESOURCES_TEMPLATE_OPTUM]: [
    {
      name: 'Resources',
      path: routes.pageResourcesTable,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.EVENTS_OPTUM]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Content',
      path: routes.pageContent,
      isComplete: false,
    },
    {
      name: 'Optional Copy Blocks',
      path: routes.pageOptionalCopyBlock,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.STATE_GOVERNMENT_OPTUM]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Content',
      path: routes.pageContent,
      isComplete: false,
    },
    {
      name: 'Bios',
      path: routes.pageBios,
      isComplete: false,
    },
    {
      name: 'Optional Copy Blocks',
      path: routes.pageOptionalCopyBlock,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX]: [
    {
      name: 'Banner',
      path: routes.pageSettings,
      isComplete: false,
    },
    {
      name: 'Content',
      path: routes.pageContent,
      isComplete: false,
    },
    {
      name: 'Bios',
      path: routes.pageBios,
      isComplete: false,
    },
    {
      name: 'Optional Copy Blocks',
      path: routes.pageOptionalCopyBlock,
      isComplete: false,
    },
  ],
  [msmPageTemplatesMap.EXTERNAL_LINK]: [
    {
      name: 'External Link',
      path: routes.externalLink,
      isComplete: false,
    }
  ]
};

export default mockBlocks;
