import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Stack } from '@mui/material';
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  CloudDownload as CloudDownloadIcon,
} from '@mui/icons-material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import styled from 'styled-components';
import {
  DataGridPremium,
  Dialog,
  IconButton,
  Loader,
  buttonWidths,
  useDocumentTitle,
} from '@clatter/ui';
import {
  downloadBlobFile,
  ExportImport,
  exportImportApi,
  formatDateTime,
  requestStatus,
  useNotices,
  usePageTitleHeader,
} from '@clatter/platform';
import { fetchMicrosites, fetchPageTemplates } from '../../../store';
import {
  deletePageTemplate,
  selectAllPageTemplates,
  updatePageTemplate,
  mapApiToStore,
} from '../../../store/page-templates.slice';
import TemplateForm from './TemplateForm';
import TemplateUsagesList from './TemplateUsagesList';
import {
  fetchPageTemplateIcons,
  selectAllPageTemplateIcons,
} from '../../../store/page-template-icons.slice';
import templatesApi from '../../../api/templates.api';
import {
  selectAllMicrosites,
  selectMicrositesEntities,
} from '../../../store/microsites.slice';
import { templatesUsageMap } from '../../../helpers/templates';
import routes, { documentTitleMap } from '../../../routes/routes';

const StyledPageTemplatesTable = styled.div`
  tbody {
    tr {
      cursor: default;
      &:hover {
        background-color: ${({ theme }) => theme.palette.grey};
        transition: all 0.2s ease-in 0s;
      }
    }
  }
`;

const Templates = () => {
  useDocumentTitle(documentTitleMap.adminTemplates);
  const dispatch = useDispatch();
  const [editableItem, setEditableItem] = useState(null);
  const [showUsedList, setShowUsedList] = useState(false);

  const pageTemplates = useSelector(selectAllPageTemplates);
  const pageTemplateIcons = useSelector(selectAllPageTemplateIcons);
  const micrositesMap = useSelector(selectMicrositesEntities);
  const microsites = useSelector(selectAllMicrosites);
  const [fetchingData, setFetchingData] = useState(false);

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Templates",
    linksType: 'msm-admin',
    routes: routes,
  });

  const { addNotice } = useNotices();
  const loading =
    useSelector(
      (state) =>
        state.pageTemplates.loadingStatus === requestStatus.pending ||
        state.microsites.loadingStatus === requestStatus.pending ||
        state.pageTemplateIcons.loadingStatus === requestStatus.pending,
    ) || fetchingData;

  const tableColumns = useMemo(
    () => [
      { field: 'display_name', headerName: 'Name', flex: 2 },
      { field: 'theme', headerName: 'Theme', flex: 0.5},
      {
        field: 'hidden',
        headerName: 'Active',
        valueFormatter: (row) => (row.value ? 'No' : 'Yes'),
        flex: 0.3,
      },
      {
        headerName: 'Date added',
        field: 'createdAt',
        renderCell: (row) => formatDateTime(row?.row?.createdAt),
        type: 'date',
        sortable: true,
        flex: 0.7,
      },
      {
        headerName: 'Date updated',
        field: 'updatedAt',
        renderCell: (row) => formatDateTime(row?.row?.updatedAt),
        type: 'date',
        sortable: true,
        flex: 0.7,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        sortable: false,
        disableExport: true,
        flex: 0.7,
        getActions: (params) => [
          <IconButton
            data-id={params.id}
            onClick={handleViewUsagesClick}
            disabled={!isTemplateUsed(params.id)}
          >
            <VisibilityOutlinedIcon />
          </IconButton>,

          <IconButton data-id={params.id} onClick={handleEditClick}>
            <EditOutlinedIcon />
          </IconButton>,

          <IconButton
            data-id={params.id}
            onClick={handleDeleteClick}
            disabled={isTemplateUsed(params.id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>,
        ],
      },
    ],
    [microsites?.length],
  );

  const handleImportSuccess = (response) => {
    dispatch(fetchPageTemplates());
    dispatch(fetchPageTemplateIcons());
    dispatch(fetchMicrosites());

    const message = [];
    if (response.data?.imported?.length > 0) {
      message.push(
        `Successfully imported ${response.data?.imported?.length} template(s).`,
      );
    }
    if (response.data?.omitted?.length > 0) {
      message.push(
        `${response.data?.omitted?.length} template(s) omitted (duplicated name).`,
      );
    }

    addNotice({
      message: message.join(' '),
      type: 'success',
      title: 'Success',
    });
  };

  const pageTemplatesUsage = useMemo(() => {
    return templatesUsageMap(microsites);
  }, [microsites?.length]);

  const isTemplateUsed = useCallback(
    (templateId) => {
      if (pageTemplatesUsage) {
        return (
          pageTemplatesUsage[templateId] &&
          pageTemplatesUsage[templateId].size > 0
        );
      }
    },
    [pageTemplatesUsage],
  );

  // need to explicity fetch the template to get related icon data
  const handleEditClick = async (event) => {
    setFetchingData(true);
    const templateId = parseInt(event.currentTarget?.dataset?.id);
    try {
      const response = await templatesApi.getTemplateById(templateId);

      setEditableItem(mapApiToStore(response.data));
    } catch (error) {
      window.alert('Sorry there was an error loading template data');
    } finally {
      setFetchingData(false);
    }
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
    setShowUsedList(false);
  };

  const handleFormSubmit = (formData) => {
    if (editableItem) {
      try {
        dispatch(
          updatePageTemplate({
            templateId: editableItem.id,
            updatedTemplate: {
              hidden: formData.hidden,
              icon_uri: formData.pageIcon?.id ?? null,
            },
          }),
        );
        handleDismissEdit();
        return;
      } catch (error) {
        window.alert('Sorry, there was an error updating the template.');
      }
    }
  };

  const handleViewUsagesClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setShowUsedList(true);

    setEditableItem(
      (clickedItemId &&
        pageTemplates.find((item) => item.id === clickedItemId)) ||
        {},
    );
  };

  const handleViewMicrositeClick = (micrositeId) => {
    window.open(
      `${process.env.NX_PREVIEW_HOST}/api/preview?secret=MY_SECRET_TOKEN&slug=sites/${micrositesMap[micrositeId]?.name}/${micrositesMap[micrositeId]?.pages[0].title}`,
      '_blank',
      'noopener',
    );
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this template?')) {
      try {
        dispatch(deletePageTemplate(parseInt(event.currentTarget.dataset.id)));
      } catch (error) {
        window.alert('Sorry, there was an error deleting the template.');
      }
    }
  };

  useEffect(() => {
    dispatch(fetchPageTemplates());
    dispatch(fetchPageTemplateIcons());
    dispatch(fetchMicrosites());
  }, [dispatch]);

  const exportTemplates = async (templatesIds) => {
    if (confirm('Export data?')) {
      setFetchingData(true);
      try {
        const exportFileName = `templates_${process.env.NX_CLIENT}_${format(
          new Date(),
          'yyyy-MM-dd_HH-mm-ss',
        )}`;
        const exportedFile = await exportImportApi.exportData({
          url: '/templates',
          params: {
            templateIds: templatesIds,
          },
        });
        return downloadBlobFile(exportedFile, `${exportFileName}.zip`);
      } catch (error) {
        const message = error.message ?  'Reason: ' + error.message : ''
        addNotice({
          message: `Error exporting data. ${message}`,
          type: 'error',
          title: 'Error',
        });
      } finally {
        setFetchingData(false);
      }
    }
  };

  const renderCustomToolbar = ({ selectedItems }) => (
    <GridToolbarContainer
      style={{
        justifyContent: 'end',
      }}
    >
      <IconButton
        tooltip="Export selected templates"
        width={buttonWidths.md}
        onClick={() => exportTemplates(selectedItems)}
      >
        <CloudDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({
          headerActions:
            <Stack spacing={1} direction="row" alignItems="center">
              <ExportImport
                showExportButton={false}
                exportUrl="/templates"
                exportDisabled={!pageTemplates || pageTemplates?.length === 0}
                onImportSuccessCallback={handleImportSuccess}
              />
            </Stack>
        })
      }

      <div>
        <StyledPageTemplatesTable>
          <DataGridPremium
            selectable
            name="adminTemplates"
            renderCustomToolbar={renderCustomToolbar}
            loading={loading}
            columns={tableColumns}
            rows={pageTemplates}
          />
        </StyledPageTemplatesTable>
      </div>
      <Dialog
        title={showUsedList ? 'Usages' : 'Edit template'}
        onCloseDialog={handleDismissEdit}
        open={!!editableItem}
      >
        {showUsedList ? (
          <TemplateUsagesList
            template={editableItem}
            pageTemplatesUsage={pageTemplatesUsage[editableItem?.id]}
            micrositesMap={micrositesMap}
            onViewMicrosite={handleViewMicrositeClick}
          />
        ) : (
          <TemplateForm
            isEdit={editableItem}
            editableItem={editableItem}
            onSubmit={handleFormSubmit}
            pageIcons={pageTemplateIcons}
          />
        )}
      </Dialog>
    </>
  );
};

export default Templates;
