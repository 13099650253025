import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page/Page';
import SiteMakerContentHead from '../../components/SiteMaker/SiteMakerContentHead';
import SiteMaker from '../../components/SiteMaker/SiteMaker';
import { pageFromStore } from '../fromStore';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMicrosites, fetchPages, fetchPageTemplates } from '../../store';
import { requestStatus, useAuth, usePageTitleHeader } from '@clatter/platform';
import { selectAllPageTemplates } from '../../store/page-templates.slice';
import { Loader } from '@clatter/ui';
import { selectPagesEntities } from '../../store/pages.slice';
import OptionalCopyBlockForm from '../../components/OptionalCopyBlockForm/OptionalCopyBlockForm';
import routes from "../../routes/routes";

const OptionalCopyBlock = () => {
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth();
  let { siteId, pageId } = useParams();

  // parse params
  pageId = parseInt(pageId);

  useEffect(() => {
    // not site/page dependent fetches
    dispatch(fetchPageTemplates());

    if (!siteId) {
      return false;
    }

    (async () => {
      const microsites = await dispatch(fetchMicrosites({ user: user, micrositeId: siteId }));
      const micrositePagesIds = (microsites?.payload?.data?.[0]?.attributes?.pages?.data || []).map(page => page?.id);

      dispatch(fetchPages({ pagesIds: micrositePagesIds }));
    })();

    window.scrollTo(0, 0);
  }, [siteId, user]);

  const loading = useSelector(
    ({ pages, pageTemplates, microsites }) =>
      pages.loadingStatus === requestStatus.pending ||
      pageTemplates.loadingStatus === requestStatus.pending ||
      microsites.loadingStatus === requestStatus.pending,
  );

  const pageEntities = useSelector(selectPagesEntities);
  const pageTemplates = useSelector(selectAllPageTemplates);
  const currentPage = pageEntities[pageId];

  const currentMicrosite = useSelector((state) => {
    if (state.pages.ids.indexOf[pageId] === -1) {
      return;
    }

    const siteId = state.microsites.ids.find((micrositeId) =>
      state.microsites.entities[micrositeId].pages.some(
        (page) => page.id === pageId,
      ),
    );

    if (!siteId || !state.microsites.entities[siteId]) {
      return;
    }

    return {
      ...state.microsites.entities[siteId],
      pages: state.microsites.entities[siteId].pages?.map((page) =>
        pageFromStore(siteId, page, pageTemplates, pageEntities[page.id]),
      ),
    };
  });

  const { renderPageTitleHeader } = usePageTitleHeader({
    currentPage: currentPage,
    currentMicrosite: currentMicrosite,
    pageTitle: "Add Optional Copy Blocks",
    routes: routes,
  });

  if (isLoading) {
    return <div>Loading authorization...</div>;
  }

  if (
    !loading &&
    (!currentMicrosite || !currentMicrosite.pages || !currentPage)
  ) {
    return <p>Couldn't load current page</p>;
  }

  const data = useMemo(() => {
    if (currentPage?.variables) {
      const variables = JSON.parse(currentPage?.variables);
      if (
        variables?.optionalCopyBlocks &&
        Array.isArray(variables?.optionalCopyBlocks)
      ) {
        return variables?.optionalCopyBlocks;
      }
      return [
        {
          enabled: false,
          headline: '',
          description: '',
          showButton: false,
          buttonText: '',
          buttonUrl: '',
        },
        {
          enabled: false,
          headline: '',
          description: '',
          showButton: false,
          buttonText: '',
          buttonUrl: '',
        },
      ];
    }
  }, [currentPage, pageId]);

  return (
    <Page>
      {loading && <Loader />}
      <SiteMaker site={currentMicrosite}>

        { renderPageTitleHeader() }

        <SiteMakerContentHead
          subhead="Please complete the following steps and click the “continue” button below"
        />

        {data && data?.length && (
          <OptionalCopyBlockForm
            data={data}
            currentMicrosite={currentMicrosite}
            currentPage={currentPage}
            pageId={pageId}
          />
        )}
      </SiteMaker>
    </Page>
  );
};

export default OptionalCopyBlock;
