import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const baseUrl = '/microsites';

const micrositesApi = {
  postMicrosite: ({ data }) =>
    getAxiosApiV2WithAuthorization({
      url: baseUrl,
      method: 'post',
      data: data,
    }),
  deleteMicrosite: ({ micrositeId }) =>
    getAxiosApiV2WithAuthorization({
      url: `${baseUrl}/${micrositeId}`,
      method: 'delete',
    })
};

export default micrositesApi;
