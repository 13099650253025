import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';
import { DevTool } from '@hookform/devtools';
import Joi from 'joi';
import RailContent from '../RailContent/RailContent';
import { useForm } from 'react-hook-form';
import {
  Button,
  errorMessages,
  StepCard,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
} from '@clatter/ui';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import PreviewButton from '../SiteMaker/PreviewButton';
import { getNextPage, isSiteComplete } from '../../helpers';
import { createContentRail } from '../../store/content-rails.slice';
import { updateContentRail, updatePage } from '../../store';
import routes from '../../routes/routes';
import { msmPageTemplatesMap } from '@clatter/platform';
import { defaultRailItem } from '../../pages/Content/Content';
import { v4 as uuidv4 } from 'uuid';

const railItemsObject = {
  description: Joi.string().min(10).required(),
  showButton: Joi.boolean(),
  buttonText: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  buttonUrl: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().uri().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
};

const railObject = {
  headline: Joi.string().min(1).required(),
  description: Joi.when('items', {
    is: Joi.array().min(1).required(),
    then: Joi.optional().allow(''),
    otherwise: Joi.string().min(10).required()
  }),
  showButton: Joi.boolean(),
  buttonText: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  buttonUrl: Joi.when('showButton', {
    is: Joi.boolean().valid(true),
    then: Joi.string().uri().required(),
    otherwise: Joi.string().allow('').optional(),
  }),
  items: Joi.array().optional().items(railItemsObject),
};

const validationSchema = Joi.object()
  .keys({
    rails: Joi.array().items(railObject),
  })
  .messages({
    'any.required': errorMessages.REQUIRED.message,
    'string.uri': errorMessages.INVALID.message,
    'string.empty': errorMessages.REQUIRED.message,
    'string.base': errorMessages.REQUIRED.message,
    'array.base': errorMessages.REQUIRED.message,
    'array.min': errorMessages.REQUIRED.message,
    'string.min': errorMessages.REQUIRED.message,
  })
  .options({
    allowUnknown: true,
  });

const mapContentRailToApi = (rail) => ({
  headline: rail.headline,
  description: rail.description,
  position: rail.position,
  showButton: rail.showButton,
  buttonText: rail.buttonText,
  buttonUrl: rail.buttonUrl,
  items: JSON.stringify(rail?.items || []),
});

const ContentForm = ({ currentMicrosite, currentPage, railsData, pageId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: joiResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      rails: railsData,
    },
  });

  const railsConfig = useMemo(() => {
    switch (currentPage?.page_template?.template) {
      case msmPageTemplatesMap.EVENTS_OPTUM:
        return {
          leftMaxItems: 10,
          centerMaxItems: 0,
          disableLeftHeadline: true,
          disableCenterHeadline: true,
          leftTitle: 'Enter left rail content',
          centerTitle: 'Enter center rail content',
          leftHeadline: 'Let’s start with a headline for the left rail content',
          centerHeadline: 'Next, enter a headline for the center rail content',
        };
      case msmPageTemplatesMap.STATE_GOVERNMENT_OPTUM:
      case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_TEAM_OPTUM_RX:
        return {
          leftMaxItems: 10,
          centerMaxItems: 10,
          disableLeftHeadline: true,
          disableCenterHeadline: true,
          leftTitle: 'Enter left rail content',
          centerTitle: 'Enter center rail content',
          leftHeadline: 'Let’s start with a headline for the left rail content',
          centerHeadline: 'Next, enter a headline for the center rail content',
        };
      case 'Payer':
      case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_RX:
      case msmPageTemplatesMap.PARTNER_SITE_WITH_TWO_CONTENT_AND_ADDITIONAL_INFORMATION_OPTUM_FINANCIAL:
      default:
        return {
          leftMaxItems: 0,
          centerMaxItems: 0,
          disableLeftHeadline: false,
          disableCenterHeadline: false,
          leftTitle: 'Enter left rail content',
          centerTitle: 'Enter center rail content',
          leftHeadline: 'Let’s start with a headline for the left rail content',
          centerHeadline: 'Next, enter a headline for the center rail content',
        };
    }
  }, [currentPage?.page_template?.template]);

  const handleUpdateContentRail = async (formData) => {
    const contentRailsQueries = formData?.rails.reduce(
      (acc, contentRail) => {
        if (contentRail.id) {
          acc.requests.push(
            dispatch(
              updateContentRail({
                id: contentRail.id,
                data: mapContentRailToApi(contentRail),
              }),
            ),
          );
        } else {
          acc.updatePage = true;
          acc.requests.push(
            dispatch(
              createContentRail({
                data: mapContentRailToApi(contentRail),
              }),
            ),
          );
        }

        return acc;
      },
      {
        requests: [],
        updatePage: false,
      },
    );

    return Promise.all(contentRailsQueries.requests).then(
      async (responseContentRailsQueries) => {
        const contentReposIds = responseContentRailsQueries.map(({ payload }) => payload.id);

        if (contentRailsQueries.updatePage) {
          await dispatch(
            updatePage({
              id: pageId,
              content_rails: contentReposIds,
            }),
          );
        }
      },
    );
  };

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      await handleUpdateContentRail(formData);
    }
    history.push(getNextPage(currentMicrosite, currentMicrosite.pages));
  };

  const onPreviewClick = async () => {
    if (isDirty && isValid) {
      const formData = getValues();
      await handleUpdateContentRail(formData);
      reset(formData);
    }
  };

  const handleAddRailItem = (railIndex) => {
    const updated = [
      ...getValues(`rails[${railIndex}].items`),
      {
        ...defaultRailItem,
        id: uuidv4(),
      },
    ];
    setValue(`rails[${railIndex}].items`, updated, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleDeleteRailItem = ({ railIndex, railItemIndex }) => {
    if (window.confirm('Are you sure you want to remove this item?')) {
      let updated = [...getValues(`rails[${railIndex}].items`)];
      updated.splice(railItemIndex, 1);
      setValue(`rails[${railIndex}].items`, updated, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  const redirectToPublish = () =>
    history.push(
      generatePath(routes.publishSite, { siteId: currentMicrosite.id }),
    );

  const renderButtons = () => (
    <>
      <Button disabled={!isValid} type="submit">
        {isDirty ? 'Save Content and continue' : 'Continue'}
      </Button>
      <PreviewButton
        siteName={currentMicrosite?.name}
        pageName={currentPage?.name}
        onPreviewClick={onPreviewClick}
      />
      <Button
        disabled={
          isDirty || !isSiteComplete(currentMicrosite, currentMicrosite?.pages)
        }
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DevTool control={control} placement="bottom-left" />
      <StepCard>
        <StepCardHeader step={1}>
          <StepCardTitle text={railsConfig?.leftTitle} />
        </StepCardHeader>
        <StepCardContent>
          <RailContent
            index={0}
            control={control}
            errors={errors}
            railsConfig={railsConfig}
            headline={railsConfig?.leftHeadline}
            maxItems={railsConfig?.leftMaxItems}
            disableHeadline={railsConfig?.disableLeftHeadline}
            handleAddRailItem={handleAddRailItem}
            handleDeleteRailItem={handleDeleteRailItem}
          />
        </StepCardContent>
      </StepCard>

      <StepCard>
        <StepCardHeader step={2}>
          <StepCardTitle text={railsConfig?.centerTitle} />
        </StepCardHeader>
        <StepCardContent>
          <RailContent
            index={1}
            control={control}
            errors={errors}
            headline={railsConfig?.centerHeadline}
            maxItems={railsConfig?.centerMaxItems}
            disableHeadline={railsConfig?.disableCenterHeadline}
            handleAddRailItem={handleAddRailItem}
            handleDeleteRailItem={handleDeleteRailItem}
          />
        </StepCardContent>
      </StepCard>

      <SiteMakerActions renderButtons={renderButtons} />
    </form>
  );
};

export default ContentForm;
