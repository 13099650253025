import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { format } from 'date-fns';
import { Stack } from '@mui/material';
import { Button, DataTable, Dialog, IconButton, Loader, useDocumentTitle } from '@clatter/ui';
import {
  ExportImport,
  formatDateTime,
  useDeepCompareMemo,
  useNotices,
  usePageTitleHeader,
  downloadFromUrl,
} from '@clatter/platform';
import ResourceForm from './ResourceForm';
import {
  fetchResources,
  deleteResource,
  selectAllResources,
} from '../../../store/resources.slice';
import routes, { documentTitleMap } from "../../../routes/routes";

const resourceColumns = [
  {
    label: 'Title',
    name: 'title',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Description',
    name: 'description',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Category',
    name: 'resource_category',
    align: 'left',
    sortable: true
  },
  {
    label: 'Created by',
    name: 'c_created_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date added',
    name: 'createdAt',
    customRender: (row) => formatDateTime(row.createdAt),
    align: 'left',
    sortable: true,
  },
  {
    label: 'Updated by',
    name: 'c_updated_by',
    align: 'left',
    sortable: true,
  },
  {
    label: 'Date updated',
    name: 'updatedAt',
    customRender: (row) => formatDateTime(row?.updatedAt) || '',
    align: 'left',
    sortable: true,
  },
];

const Resources = () => {
  useDocumentTitle(documentTitleMap.adminResources)
  const [editableItem, setEditableItem] = useState(null);
  const dispatch = useDispatch();
  const { addNotice } = useNotices();

  const { renderPageTitleHeader } = usePageTitleHeader({
    pageTitle: "Resources",
    linksType: 'msm-admin',
    routes: routes,
  });

  const handleImportSuccess = (response) => {
    dispatch(fetchResources());

    const message = [];
    if (response.data?.imported?.length > 0) {
      message.push(
        `Successfully imported ${response.data?.imported?.length} resources.`,
      );
    }
    if (response.data?.omitted?.length > 0) {
      message.push(
        `${response.data?.omitted?.length} resource(s) omitted (duplicated name).`,
      );
    }

    addNotice({
      message: message.join(' '),
      type: 'success',
      title: 'Success',
    });
  };

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  const resources = useSelector(selectAllResources);

  const loading = useSelector(
    (state) => state.resources.loadingStatus === 'loading',
  );

  const mappedResources = useDeepCompareMemo(() =>  resources.map(item => ({
    ...item,
    resource_category: item?.resource_category?.categoryName,
  })), [resources]);

  const handleEditClick = (event) => {
    const clickedItemId = parseInt(event.currentTarget?.dataset?.id);

    setEditableItem(
      (clickedItemId &&
        resources.find((resource) => resource.id === clickedItemId)) ||
        {},
    );
  };

  const handleDownloadClick = (row) => {
    if (!row?.asset?.url) {
      return false;
    }

    return downloadFromUrl(row?.asset?.url, row?.asset?.url.split('/').pop(), true);
  };

  const handleDismissEdit = () => {
    setEditableItem(null);
  };
  
  const handleOnSuccess = () => {
    setEditableItem(null);
    dispatch(fetchResources());
  };

  const handleDeleteClick = (event) => {
    if (window.confirm('Are you sure you want to remove this resource?')) {
      dispatch(deleteResource(parseInt(event.currentTarget.dataset.id)));
    }
  };

  const isEdit = editableItem && 'title' in editableItem;

  return (
    <>
      {loading && <Loader />}

      { renderPageTitleHeader({
        headerActions:
          <Stack spacing={1} direction="row" alignItems="center">
            <ExportImport
              exportUrl="/resources"
              exportFileName={(() =>
                `resources_${process.env.NX_CLIENT}_${format(
                  new Date(),
                  'yyyy-MM-dd_HH-mm-ss',
                )}`)()}
              exportDisabled={!mappedResources || mappedResources?.length === 0}
              onImportSuccessCallback={handleImportSuccess}
            />
            <Button onClick={handleEditClick}>Add resource</Button>
          </Stack>
        })
      }
      <div>
        <DataTable
          autofit
          columns={resourceColumns}
          defaultSortField="title"
          filterColumns={['title', 'description', 'resource_category' ]}
          name="adminResources"
          rows={mappedResources}
          renderActionColumn={(row) => (
            <div style={{ minWidth: 100 }}>
              <IconButton data-id={row.id} onClick={handleEditClick}>
                <EditOutlinedIcon />
              </IconButton>

              <IconButton onClick={() => handleDownloadClick(row)} disabled={!row?.asset?.url}>
                <DownloadOutlinedIcon />
              </IconButton>

              <IconButton data-id={row.id} onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </div>
          )}
        />
      </div>
      <Dialog
        title={isEdit ? 'Edit resource' : 'Add resource'}
        open={!!editableItem}>
        <ResourceForm
          isEdit={isEdit}
          editableItem={editableItem}
          onSuccess={handleOnSuccess}
          onClose={handleDismissEdit}
        />
      </Dialog>
    </>
  );
};

export default Resources;
