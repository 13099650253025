import React, { useState } from 'react';
import Joi from "joi";
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import {
  ENABLE_CO_BRAND_LOGO_REL,
  eventRequestTypes,
  msmPageTemplatesMap,
  useAuth,
  useDeepCompareEffect,
  useEventTracking
} from '@clatter/platform';
import { cloneDeep } from 'lodash';
import {
  Button,
  Dialog,
  FormControl,
  StepCard,
  StepCardAction,
  StepCardContent,
  StepCardHeader,
  StepCardTitle,
  TextControl,
} from '@clatter/ui';
import {
  ViewComfyOutlined as ViewCompactOutlinedIcon,
  AddOutlined as AddOutlinedIcon,
} from '@mui/icons-material';
import { addPage, fetchCobrandLogos, updateMicrosite, updatePage } from '../../store';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import ListSelector from '../../components/ListSelector/ListSelector';
import CreateCompanyForm from '../CreateCompanyForm/CreateCompanyForm';
import { deleteCobrandLogo } from '../../store/cobrand-logos.slice';
import {
  arrayMove,
  compareIdentifiers,
  getNextPage,
  isSiteComplete,
} from '../../helpers';
import SiteMakerActions from '../SiteMaker/SiteMakerActions';
import routes from '../../routes/routes';

const SITES_HOST = process.env.NX_SITES_HOST;

const StyledSiteSettingsForm = styled.form`
  .microsite-name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .microsite-url {
      padding: 0 8px;
      font-size: 14px;
      color: #666;
    }

    .microsite-control {
      flex: 1;
    }
  }

  .logo-upload-picker {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  }
`;

const pagesSchema = Joi.array()
  .min(1)
  .items(
    Joi.object({
      id: Joi.number().required(),
      page_template: Joi.object({
        id: Joi.number(),
        template: Joi.string().required(),
      }).required(),
    }),
  )
  .options({ abortEarly: false, stripUnknown: true })
  .messages({
    'array.min': 'Add at least one page',
    'pages.externalLinkCanNotBeTheFirstPage': '"External Link" page type can\'t be the first one.',
    'pages.externalLinkCanNotBeOnlyPage': '"External Link" page types can\'t be the only pages added.',
  });

const externalLinkCanNotBeTheFirstPage = (value, helpers) => {
  if (value.length === 1 && value[0].page_template.template === msmPageTemplatesMap.EXTERNAL_LINK) {
    return helpers.error('pages.externalLinkCanNotBeOnlyPage');
  }

  if (value?.[0]?.page_template?.template === msmPageTemplatesMap.EXTERNAL_LINK) {
    return helpers.error('pages.externalLinkCanNotBeTheFirstPage');
  }
  return value;
};

const externalLinkCanNotBeOnlyPage = (value, helpers) => {
  if (value.every(page => page.page_template.template === msmPageTemplatesMap.EXTERNAL_LINK)) {
    return helpers.error('pages.externalLinkCanNotBeOnlyPage');
  }
  return value;
};

const SiteSettingsForm = ({
  heroImages,
  microsite,
  pageTemplates,
  pages,
  cobrandLogos,
  loading,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, activeUser } = useAuth();
  const { trackEvent } = useEventTracking();

  const [showCreateCompanyDialog, setShowCreateCompanyDialog] = useState(false);
  const [formInitiated, setFormInitiated] = useState(false);
  const [pagesError, setPagesError] = useState(null);

  const {
    control,
    formState: { errors, isDirty, isValid },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  // validate pages (because they are passed down
  // to this component and are not included in
  // the form we need to use hook instead
  // of direct form validation)
  useDeepCompareEffect(() => {
    const { error } = pagesSchema
      .custom(externalLinkCanNotBeOnlyPage)
      .custom(externalLinkCanNotBeTheFirstPage)
      .validate(pages);

    if (error?.details) {
      setPagesError({ message: error.details[0].message });
      return;
    }

    setPagesError(null);
  }, [pages])

  //region METHODS
  const toggleShowCreateCompanyDialog = () => {
    setShowCreateCompanyDialog(!showCreateCompanyDialog);
  };

  const handleSelectLogo = (logo) => {
    const formPropToChange = ENABLE_CO_BRAND_LOGO_REL ? 'cobrand_logo_rel' : 'cobrand_logo';
    setValue(formPropToChange, logo, {
      shouldDirty: true,
      shouldValidate: true,
    });
    toggleShowCreateCompanyDialog();
    dispatch(fetchCobrandLogos());
  };

  const handleFormSubmit = async (formData) => {
    if (isDirty) {
      const updatedMicrosite = {
        id: microsite.id,
        name: formData.name,
        client_name: formData.client_name,
        c_updated_by: activeUser.email,
      };

      if (ENABLE_CO_BRAND_LOGO_REL) {
        updatedMicrosite['cobrand_logo_rel'] = formData.cobrand_logo_rel;
      } else {
        updatedMicrosite['cobrand_logo'] = formData.cobrand_logo.asset.id;
      }

      await dispatch(updateMicrosite(updatedMicrosite)).then((response) => {
        if (!response.error) {
          history.push(getNextPage(microsite, microsite.pages));
        }
      });
    } else {
      history.push(getNextPage(microsite, microsite.pages));
    }
  };

  const getDefaultPage = (templateTitle) => {
    const pageTemplate = pageTemplates.find(({ title }) => title === templateTitle);
    const defaultPage = {
      title: templateTitle,
      page_template: pageTemplate,
    };

    const pageVariables = {
      theme: pageTemplate.theme,
    };

    if (pageTemplate.template !== 'Video Template') {
      const defaultHeroImage =
        heroImages && Array.isArray(heroImages) && heroImages.length
          ? heroImages[0]
          : null;

      pageVariables.headline = 'A better path to affordable benefits';
      pageVariables.introCopy = 'Developing a lasting partnership with [client name]';

      defaultPage.banner_image = defaultHeroImage?.id;
    }

    defaultPage.variables = JSON.stringify(pageVariables);

    return defaultPage;
  };

  const handleAddPage = async (templateTitle) => {
    const nextMicrosite = {
      ...microsite,
      ...getValues(),
      cobrand_logo: getValues('cobrand_logo')?.asset?.id,
      c_updated_by: activeUser.email,
    };
    const response = await dispatch(addPage(getDefaultPage(templateTitle)));
    // note how adding a page always PUTs a microsite with .pages[] an array
    // of string ids. deleting a page PUTs the micrsite with the array of
    // page {} objects... (We're not having problems with delete page...)
    nextMicrosite.pages = microsite.pages.reduce((acc, curr) => {
      if (curr?.id) {
        acc.push(curr.id);
      }
      return acc;
    }, []);
    nextMicrosite.pages.push(response.payload.id);

    trackEvent(eventRequestTypes.micrositePageCreated, {
      microsite_id: microsite.id,
      microsite_name: microsite.name,
      microsite_page_id: response.payload.id,
      microsite_page_name: response.payload.title,
    });

    // updateMicrosite() will cause a render by updating the microsites slice
    await dispatch(updateMicrosite(nextMicrosite));
  };

  const handleMovePage = (oldIndex, newIndex) => {
    const nextPages = arrayMove(microsite.pages, +oldIndex, +newIndex);
    const nextMicrosite = {
      ...getValues(),
      id: microsite.id,
      pages: nextPages,
      cobrand_logo: getValues('cobrand_logo')?.asset?.id,
      c_updated_by: activeUser.email,
    };
    return dispatch(updateMicrosite(nextMicrosite));
  };

  const handleDeletePage = (deletedValue) => {
    const nextMicrosite = {
      id: microsite.id,
      pages: microsite.pages.filter((page) => page.id !== deletedValue.id),
      ...getValues(),
      cobrand_logo: getValues('cobrand_logo')?.asset?.id,
      c_updated_by: activeUser.email,
    };
    dispatch(updateMicrosite(nextMicrosite));
  };

  const handleDeleteLogo = (event) => {
    const logoId = parseInt(event.currentTarget.dataset.id);

    if (logoId) {
      dispatch(deleteCobrandLogo(logoId));
      const selectedCobrandLogo = watch('cobrand_logo');
      if (
        selectedCobrandLogo &&
        compareIdentifiers(selectedCobrandLogo.id, logoId)
      ) {
        setValue('cobrand_logo', null, { shouldValidate: true });
      }
    }
  };

  const handleItemChange = (updatedPage) => {
    const nextPage = {
      id: updatedPage.id,
      title: updatedPage?.title?.trim(),
    };

    dispatch(updatePage(nextPage)).then((data) => {
      const nextMicrosite = cloneDeep(microsite);
      const changedPageIndex = nextMicrosite.pages.findIndex(
        (page) => page.id === updatedPage.id,
      );
      nextMicrosite.pages[changedPageIndex] = data.payload;
      dispatch(updateMicrosite({
        ...nextMicrosite,
        c_updated_by: activeUser.email,
      }));
    });
  };

  const redirectToPublish = () => {
    history.push(generatePath(routes.publishSite, { siteId: microsite.id }));
  };
  //endregion

  //region EFFECTS
  // set form default values
  // setting it in the useForm does not work properly
  useDeepCompareEffect(() => {
    // loading should enable to wait until all data
    // has been fetched including waiting for cobrandLogos,
    // so we can properly reset form to initial state
    if (formInitiated || loading) {
      return;
    }

    const initialFormState = {
      id: microsite.id,
      name: microsite.name,
      client_name: microsite.client_name,
      // it is actually the underlying asset that is currently stored in the cobrand_logo
      // field. so, we have to look up the cobrand logo object
      // default constructed microsites don't have .cobrand_logo attribute
      cobrand_logo: cobrandLogos
        .filter((logo) => logo.asset && logo.asset.id)
        .find(
          (logo) =>
            microsite.cobrand_logo &&
            logo.asset.id === microsite.cobrand_logo.id,
        ),
    }

    if (ENABLE_CO_BRAND_LOGO_REL) {
      initialFormState['cobrand_logo_rel'] = microsite?.cobrand_logo_rel;
    }

    reset(initialFormState)

    // we need to prevent triggering reset after each
    // loading state changed therefore we need to keep track
    // if the action of reseting form has already been executed.
    setFormInitiated(true);

  }, [microsite?.id, cobrandLogos, loading])
  //endregion

  const renderButtons = () => (
    <>
      <Button
        disabled={!isValid || !!pagesError}
        type="submit"
      >
        {isDirty ? 'Save and continue' : 'Continue'}
      </Button>
      <Button
        disabled={isDirty || !isSiteComplete(microsite, microsite.pages)}
        onClick={redirectToPublish}
      >
        Publish
      </Button>
    </>
  );

  return (
    <>
      <StyledSiteSettingsForm onSubmit={handleSubmit(handleFormSubmit)}>
        <StepCard>
          <StepCardHeader step={1}>
            <StepCardTitle text="Enter microsite name and customer name" />
          </StepCardHeader>
          <StepCardContent>
            <FormControl
              label="Give your microsite a name. We suggest the client or prospect name."
              error={errors.name}
            >
              <div className="microsite-name">
                <div className="microsite-url">{`${SITES_HOST}/sites/`}</div>
                <div className="microsite-control">
                  <TextControl
                    {...register('name', {
                      required: { value: true, message: 'Field is required' },
                    })}
                    placeholder="enter name for the microsite"
                  />
                </div>
              </div>
            </FormControl>
            <FormControl
              noPadding
              label="Enter a client or prospect name. This may be the same name as above."
              error={errors.client_name}
            >
              <TextControl
                {...register('client_name', {
                  required: { value: true, message: 'Field is required' },
                })}
                placeholder="enter a client or prospect name"
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={2}>
            <StepCardTitle text="Upload your client/prospect logo" />
            <StepCardAction
              onClick={toggleShowCreateCompanyDialog}
              label="Add company"
              icon={AddOutlinedIcon}
            />
          </StepCardHeader>
          <StepCardContent>
            <FormControl noPadding error={ENABLE_CO_BRAND_LOGO_REL ? errors.cobrand_logo_rel : errors.cobrand_logo}>
              <Controller
                rules={{
                  required: { value: true, message: 'Field is required' },
                }}
                render={({ field: { onChange, value } }) => (
                  <ImagePicker
                    images={cobrandLogos}
                    titleProperty="company"
                    urlProperty="asset.url"
                    onChange={onChange}
                    value={value}
                    imageAspectRatio="10/4"
                  />
                )}
                control={control}
                name={ENABLE_CO_BRAND_LOGO_REL ? "cobrand_logo_rel" : "cobrand_logo"}
              />
            </FormControl>
          </StepCardContent>
        </StepCard>
        <StepCard>
          <StepCardHeader step={3}>
            <StepCardTitle text="Add page templates" />
          </StepCardHeader>
          <StepCardContent>
            <p>
              Add a page template for each page you plan to include in your
              microsite. (Sales, please choose the Prospect Site template).
            </p>
            <FormControl error={pagesError} >
              <ListSelector
                disabled={!isValid}
                itemIcon={ViewCompactOutlinedIcon}
                handleMovePage={handleMovePage}
                onAdd={handleAddPage}
                onDelete={handleDeletePage}
                onItemChange={handleItemChange}
                options={pageTemplates}
                value={pages}
                assetDisplayName="Page Templates"
              />
            </FormControl>

          </StepCardContent>
        </StepCard>
        <SiteMakerActions renderButtons={renderButtons} />
      </StyledSiteSettingsForm>
      <Dialog
        title="Create company"
        onCloseDialog={toggleShowCreateCompanyDialog}
        open={showCreateCompanyDialog}
      >
        <CreateCompanyForm onSuccess={handleSelectLogo} user={user} />
      </Dialog>
    </>
  );
};

export default SiteSettingsForm;
